import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';

	export const StyledTextGrid = styled.section`
		margin: 0 0 ${ pxtorem(160) };

		@media (max-width: 768px) {
			margin: 0 0 ${ pxtorem(100) };
		}

		.inner {
			display: flex;

			@media (max-width: 1200px) {
				flex-direction: column;
			}
		}

		.header {
			width: 45%;
			margin-right: 10%;

			@media (max-width: 1200px) {
				width: 100%;
				margin: 0 0 60px;
			}

			@media (max-width: 768px) {
				text-align: center;
			}
		}

		.content {
			flex: 1;
		}

		.title {
			font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
			text-transform: uppercase;
			margin: 0;

			@media (min-width: 1920px) {
				font: 300 120px/90% var(--primaryFont);
			}
		}

		.subtitle {
			font: 400 ${pxtorem(16)}/${pxtorem(20)} var(--secondaryFont);
		}

		.grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: ${pxtorem(86)} ${pxtorem(57)};

			@media (max-width: 768px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				grid-gap: 40px 0;
			}
		}

		.heading {
			font: 400 ${pxtorem(20)}/${pxtorem(24)} var(--secondaryFont);
			text-transform: uppercase;
		}

		.list {
			list-style: none;
			padding: 0;
			margin: 0;
		}

		.item {
			font: 400 ${pxtorem(20)}/${pxtorem(30)} var(--secondaryFont);
		}
	}
`
import React, { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';

import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledContentBanner } from './ContentBanner.style';

export const ContentBanner: React.FC<ContentBannerProps> = ({ data }) => {
  //#region Variables
  const { title, text, grid } = data[0] || {};
  //#endregion

  //#region Templating
  return (
    <StyledContentBanner>
      <Wrapper width={1700}>
        <div className="inner">
          <div className="title">
            <ShowOnScroll>
              <h2>{title}</h2>
            </ShowOnScroll>
          </div>
          <div className="content">
            <ShowOnScroll delay={100}>
              <ReactMarkdown className="text">
                {text}
              </ReactMarkdown>
            </ShowOnScroll>
            <div className="locations">
              <ShowOnScroll delay={150}>
                <h3 className="heading">{grid?.heading}</h3>
                <ReactMarkdown className="text">
                  {grid?.text}
                </ReactMarkdown>
              </ShowOnScroll>
            </div>
          </div>
        </div>
      </Wrapper>
    </StyledContentBanner>
  )
}

export interface ContentBannerProps {
  data: ContentBannerData[];
}

export interface ContentBannerData {
  title: string;
  text: string;
  grid: {
    heading: string;
    text: string;
  }
}
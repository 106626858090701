import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledTextBlock = styled.section`
		.inner {
			display: flex;
			border-top: 1px solid #CFCDCB;
			padding: ${pxtorem(160)} 0;

			@media (max-width: 1200px) {
				flex-direction: column;
			}

			@media (max-width: 768px) {
				padding: ${pxtorem(80)} 0 ${pxtorem(100)};
			}
		}

		.header {
			width: 45%;
			margin-right: 10%;

			@media (max-width: 1200px) {
				width: 75%;
				margin: 0 auto 48px;
			}

			@media (max-width: 768px) {
				text-align: center;
			}
		}

		.content {
			flex: 1;
		}

		.title {
			font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
			text-transform: uppercase;
			margin: 0;

			@media (min-width: 1920px) {
				font: 300 120px/90% var(--primaryFont);
			}
		}

		.subtitle {
			text-transform: uppercase;
			font: 400 ${pxtorem(16)}/${pxtorem(20)} var(--secondaryFont);
			margin-top: 60px;
			width: 60%;

			@media (max-width: 768px) {
				text-align: center;
				margin-top: 24px;
				width: 100%;
			}
		}

		.text {
			font: 400 ${pxtorem(20)}/${pxtorem(30)} var(--secondaryFont);
			width: 80%;

			@media (max-width: 1200px) {
				width: 100%;
			}

			@media (max-width: 768px) {
				text-align: center;
			}

			p {
				margin-top : 0;
			}
		}
	}
`
import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledContentBanner = styled.section`
	.inner {
		display: flex;
		padding: ${pxtorem(160)} 0;
		border-bottom: 1px solid #CFCDCB;

		@media (max-width: 1200px) {
			flex-direction: column;
    }

		@media (max-width: 768px) {
			padding: ${pxtorem(100)} 0;
		}
	}

	.title {
    margin-right: 10%;
		width: min-content;

		@media (max-width: 1200px) {
			margin: 0 0 ${pxtorem(80)};
    }

		@media (max-width: 768px) {
			margin: 0 0 28px;
			text-align: center;
			width: 100%;
		}

		h2 {
      font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
			text-transform: uppercase;
			margin: 0;

      @media (min-width: 1920px) {
        font: 300 120px/90% var(--primaryFont);
      }
		}
	}

	.content {
		flex-grow: 1;
		display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10%;

		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}

	.text {
    font: 400 ${pxtorem(20)}/${pxtorem(30)} var(--secondaryFont);
    margin: 0;

		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.locations {
		@media (max-width: 768px) {
			margin-top: 36px;
		}
	}

	.heading {
		font: 400 ${pxtorem(16)}/${pxtorem(19)} var(--secondaryFont);
		text-transform: uppercase;
		margin-bottom: 28px;
	}
`
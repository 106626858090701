import { graphql } from 'gatsby';
import React from 'react';
import { paths } from '../utils/paths';
import { withLang } from '../utils/reactTools';
import { getLocaleMdData } from '../utils/Tools';
import { AboutData, AboutPage } from '../components/AboutPage/AboutPage';
import { Page } from '../components/Page';
import { SEO, SEOProps } from '../components/SEO/SEO';

export const About: React.FC<AboutProps> = ({ data, language }) => {
  const _data = getLocaleMdData(data);
  const { pageTheme, seo } = _data[language] || {};

  const globalSEO: SEOProps = (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title, description, ogImage } = (seo ? seo[0] : null) || {};

  return (
    <Page
      background={pageTheme}
      language={language}
      slugs={{ en: `${paths.en.prefix}/about-us`, fr: `/${paths.fr.prefix}/a-propos` }}
    >
      <SEO
        title={title ? title : globalSEO.title}
        description={description ? description : globalSEO.description}
        ogImage={ogImage ? ogImage : globalSEO.ogImage} />

      <AboutPage data={data} />
    </Page>
  )
  //#endregion
}

interface AboutProps extends LocalizedMarkdownData<AboutData>, PropsWithLang<AboutData> { }

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages/about-us.md/"}) {
      fields {
        locales {
          en {
            pageTheme
            ...heroBannerFragmentEN
            ...contentBannerFragmentEN
            ...textGridFragmentEN
            ...teamCarouselFragmentEN
            ...textBlockFragmentEN
            ...textBannerFragmentEN
          }
        }
      }
    }
  }
`;

export default withLang(About, 'en');

import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem, pxtovw } from '../../utils/Tools';
import { StyledHeroBanner } from '../HeroBanner/HeroBanner.style';
import { StyledTextGrid } from '../TextGrid/TextGrid.style';

export const StyledAboutPage = styled.div`
${ StyledHeroBanner } {
    background: var(--color);
    position: relative;
    padding-bottom: ${pxtovw(191)};
    /* overflow: hidden; */

    &:before {
      content: '';
      position: absolute;
      width: ${pxtovw(2621)};
      height: ${pxtovw(2205)};
      top: ${pxtovw(-1360)};
      left: 50%;
      transform: translateX(-50%);
      background: var(--background);
      border-radius: 100%;
    }

    @media (min-width: 1920px) {
      padding-bottom: 191px;
      &:before {
        width: 2621px;
        height: 2205px;
        top: -1360px;
      }
    }

    @media (max-width: 1300px) {
      &:before {
        top: ${pxtovw(-1250)};
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 123px;
      &:before {
        top: auto;
        width: 924px;
        height: 1148px;
        bottom: 0px;
      }
    }

    ${ Wrapper } {
      --width: 1664px;
    }

    .subtitle {
      text-transform: none;
      max-width: 749px;
      margin-right: auto;
      margin-left: auto;

      @media (max-width: 768px) {
        max-width: 400px;
      }
    }
  }

  ${StyledTextGrid} .inner {
    padding-top: ${pxtorem(160)};
  }
`;
import React from 'react';

import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledTextGrid } from './TextGrid.style';

export const TextGrid: React.FC<TextGridProps> = ({ data }) => {
  //#region Variables
  const { title, grid } = data[0] || {};
  //#endregion

  //#region Templating
  return (
    <StyledTextGrid>
      <Wrapper width={1700}>
        <div className="inner">
          <div className="header">
            <ShowOnScroll>
              <h2 className="title">{title}</h2>
            </ShowOnScroll>
          </div>
          <div className="content">
            <ShowOnScroll>
              <div className="grid">
                {grid?.map((block, i) => (
                  <div key={(block.heading ?? block.heading) + i} className="block">
                    <h3 className="heading">{block.heading}</h3>
                    <ul className="list">
                      {block.list.map((item, i: number) => (
                        <li key={item.label} className="item">{item.label}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </ShowOnScroll>
          </div>
        </div>
      </Wrapper>
    </StyledTextGrid>
  )
}

export interface TextGridProps {
  data: TextGridData[];
}

export interface TextGridData {
  title: string;
  grid: GridBlock[];
}

export interface GridBlock {
  heading: string;
  list: {
    label: string;
  }[];
}

import React from 'react';

import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledTextBlock } from './TextBlock.style';
import ReactMarkdown from 'react-markdown';

export const TextBlock: React.FC<TextBlockProps> = ({ data }) => {
  //#region Variables
  const { title, subtitle, text } = data[0] || {};
  //#endregion

  //#region Templating
  return (
    <StyledTextBlock>
      <Wrapper width={1700}>
        <div className="inner">
          <div className="header">
            <ShowOnScroll>
              <h2 className="title">{title}</h2>
              <div className="subtitle">
                <ReactMarkdown>
                  {subtitle}
                </ReactMarkdown>
              </div>
            </ShowOnScroll>
          </div>
          <div className="content">
            <ShowOnScroll>
              <div className="text">
                <ReactMarkdown>
                  {text}
                </ReactMarkdown>
              </div>
            </ShowOnScroll>
          </div>
        </div>
      </Wrapper>
    </StyledTextBlock>
  )
}

export interface TextBlockProps {
  data: TextBlockData[];
}

export interface TextBlockData {
  title: string;
  subtitle: string;
  text: string;
}
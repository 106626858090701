import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import { About } from '../about-us';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages/about-us.md/"}) {
      fields {
        locales {
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
            ...contentBannerFragmentFR
            ...textGridFragmentFR
            ...teamCarouselFragmentFR
            ...textBlockFragmentFR
            ...textBannerFragmentFR
          }
        }
      }
    }
  }
`;

export default withLang(About, 'fr');

import React, { useContext } from 'react';
import { Section } from 'src/styles/Section.style';
import { getLocaleMdData } from '../../utils/Tools';
import { PactContext } from '../PactContext';
import { StyledAboutPage } from './AboutPage.style';
import { HeroBanner, HeroBannerData } from '../HeroBanner/HeroBanner';
import { ContentBanner, ContentBannerData } from '../ContentBanner/ContentBanner';
import { TextGrid, TextGridData } from '../TextGrid/TextGrid';
import { TextBanner, TextBannerData } from '../TextBanner/TextBanner';
import { TextBlock, TextBlockData } from '../TextBlock/TextBlock';
import { SEOProps } from '../SEO/SEO';

export const AboutPage: React.FC<AboutPageProps> = ({ data }): JSX.Element => {
  const { language } = useContext(PactContext);
  const _data = getLocaleMdData(data);

  const {
    heroBanner,
    contentBanner,
    textGrid,
    textBlock,
    textBanner
  } = _data[language] || {};

  return (
    <StyledAboutPage>
      <HeroBanner data={heroBanner[0]} />
      <Section bg="pebble">
        <ContentBanner data={contentBanner} />
        <TextGrid data={textGrid} />
        <TextBlock data={textBlock} />
      </Section>
      <TextBanner data={textBanner} />
    </StyledAboutPage>
  );
}

interface AboutPageProps {
  data: LocalizedMarkdownData<AboutData>['data'];
}

export interface AboutData {
  pageTheme: ThemeBG;
  seo: SEOProps[];
  heroBanner: HeroBannerData[];
  contentBanner: ContentBannerData[];
  textGrid: TextGridData[];
  textBlock: TextBlockData[];
  textBanner: TextBannerData[];
}
